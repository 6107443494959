.view {
    text-align: left;
}

.data_table{
    display: flex;
    flex-direction: column;
    color: rgb(232, 230, 227);
}

.legend_table {
    display: flex;
}

.table_component {
    color: rgb(232, 230, 227);
}

.intro {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
    color: rgb(92, 157, 214);
}

.tennis_title {
    color: rgb(178, 233, 106);
    margin-bottom: 20px;
    margin-top: 20px;
}