@import "/node_modules/bootstrap/scss/bootstrap";

.experience-section {
    text-align: left;
}

.display-linebreak {
    white-space: pre-line;
    font-size: 15px;
    color: rgb(178, 171, 161);
}

.experience-img {
    border-radius: 25px;
	margin: 'auto';
	display: 'block';
}

.img-container {
    margin-left: 14px;
    margin-right: 14px;
}
.body-container {
    margin-left: 14px;
    margin-right: 14px;
}
.company {
    margin-bottom: 5px;
    color: rgb(92, 157, 214);
}
.title {
    color: rgb(178, 233, 106);
}
.date {
    font-style: italic;
    color: rgb(178, 233, 106);
}
.heading-container {
    padding-top: 10px;
}